export const validCategories = [
  'Pizza',
  'Sushi',
  'Thai',
  'Chinese',
  'Ramen',
  'Soup',
  'Italian',
  'Vegan',
  'Healthy',
  'Indian',
  'Burgers',
  'Breakfast',
  'Salad',
  'Mexican',
  'Vegetarian',
  'Sandwiches',
  'Mediterranean',
  'Korean',
] as const;

export type ValidCategory = typeof validCategories[number];
