import React from 'react';
import styled from 'styled-components';
import { CityNode, StoreNode, MenuItem } from '../../api/caviar-api';
import ComingSoonPNG from '../../images/coming-soon.png';
import StarSVG from '../../images/stars.inline.svg';
import { media } from '../../includes/breakpoints';
import { Link } from 'gatsby';
import { ValidCategory } from '../../includes/categories';
import { Pagination } from './Pagination';

const RestaurantStyle = styled.div`
  .stores-header {
    display: flex; justify-content: space-between;
    margin-top: 50px; margin-bottom: 25px;

    > h2 {
      font-size: 30px;
      font-weight: 600;
      line-height: 28px;
      margin: 0;
      text-align: left;
    }

    .reset-btn {
      background-color: #eff1f3;
      border-radius: 4px;
      box-shadow: #eff1f3 0px 0px 0px 1px inset;
      color: black;
      font-size: 14px;
      line-height: 16px;
      padding: 9px 12px;
      transition: background-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;

      &:hover {
        background-color: #ccd1d5;
        box-shadow: #ccd1d5 0px 0px 0px 1px inset;
      }
    }
  }

  .restaurant-pagination {
    display: flex; align-items: center; justify-content: center;
    text-align: center;

    > ul {
      display: flex;
      list-style: none;
      margin: 0 12px;
      padding: 0;

      li {
        font-size: 25px;
        font-weight: bold;
        margin: 0 8px;

        a {
          color: black;
        }

        &.active a {
          color: #eb1700;
          pointer-events: none;
        }
      }
    }

    span {
      display: inline-flex;
    }
  }

  &:not(.filtered) {
    .filtered-show {
      display: none;
    }
  }

  &.filtered {
    .filtered-hide {
      display: none;
    }
  }
`;

const RestaurantGrid = styled.div`
  display: flex; flex-wrap: wrap;

  .restaurant {
    margin-bottom: 15px;
    position: relative;
    width: 100%;

    ${media.mobile`
      margin-right: 20px; margin-bottom: 30px;
      padding: 0;
      width: calc(50% - 10px);

      @media(min-width: 481px) and (max-width: 1023px) {
        &:nth-child(even) {
          margin-right: 0;

          .store-grid {
            margin-left: calc(-100% - 20px);
          }
        }
      }
    `};

    ${media.desktop`
      width: calc(33.33% - 13.33px);

      &:nth-child(3n+2) {
        .store-grid {
          margin-left: calc(-100% - 20px);
        }
      }

      &:nth-child(3n) {
        margin-right: 0;

        .store-grid {
          margin-left: calc(-200% - 40px);
        }
      }
    `};

    a {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      padding: 12px;
      position: relative;

      &:hover {
        color: white;
      }

      ${media.mobile`
        box-shadow: none;
      `};
    }

    &:not(.active) {
      .popular-items:before {
        display: none;
      }

      .popular-items button:after {
        border-right: 0; border-bottom: 0;
      }

      .store-grid {
        display: none;
      }
    }

    &.active {
      .popular-items button:after {
        border-top: 0; border-left: 0;
      }

      .store-grid {
        display: flex;
        z-index: 9;
      }
    }
  }

  .restaurant-content {
    flex-grow: 1;
    position: relative;
  }

  a {
    display: flex;

    ${media.mobile`
      display: block;
    `};
  }

  img {
    margin-right: 5%;
    width: 55%;


    ${media.mobile`
      width: 100%; max-width: none;
    `};
  }

  h2 {
    color: black;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    margin: 5px 0 10px;
    text-align: left;

    ${media.mobile`
      margin: 10px 0 15px;
    `};
  }

  .restaurant-details {
    color: #6E767E;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 30px;

    ${media.mobile`
      margin-bottom: 15px;
    `};

    ${media.tablet`
      margin-bottom: 0;
    `};
  }

  .rating-stars {
    margin-top: 6px;
  }

  .popular-items {
    color: black;
    display: flex; justify-content: space-between;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    padding: 5px;
    position: absolute; right: -5px; bottom: -5px;
    white-space: nowrap;
    width: 100%;

    &:before {
      content: "";
      border: 20px solid transparent;
      position: absolute; right: -10px; bottom: -46px;
      border-bottom-color: #fafafa;
    }

    ${media.mobile`
      justify-content: normal;
      padding: 0;
      position: static;
    `};

    ${media.tablet`
      padding: 5px;
      position: absolute; right: -5px; bottom: -5px;
      width: auto;
    `};

    button {
      background: #eee;
      border: 0;
      border-radius: 18px;
      height: 18px;
      position: relative;
      width: 18px;

      &:after {
        border: 2px solid black;
        content: "";
        height: 5px;
        position: absolute;
        top: calc(50% - 3px);
        left: calc(50% - 4px);
        transform: rotate(45deg);
        width: 5px;
      }

      ${media.mobile`
        margin-left: 25px;
      `};
    }
  }
`;

const StoreGrid = styled.div`
  background: #fafafa;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  display: none;
  flex-direction: column;
  padding: 0 20px;
  position: relative; top: 3px;

  ${media.mobile`
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 25px;
    width: 200%;
  `};

  ${media.desktop`
    width: 300%;
  `};

  > div {
    margin-top: 15px; margin-bottom: 15px;

    ${media.tablet`
      margin-right: 2%;
      width: 23.5%;

      &:nth-child(4) {
        margin-right: 0;
      }
    `};
  }

  h4 {
    color: black;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-top: 0; margin-bottom: 15px;
  }

  .description {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0; margin-bottom: 15px;
  }

  .price {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }

  a {
    align-self: center;
    background: #FF5D37;
    border-radius: 2px;
    border: 0;
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin: 15px auto 30px;
    max-width: 150px;
    padding: 9px 33px;
  }
`;

const CaviarMenuItem = ({ menuItem }:{ menuItem:MenuItem }) => {
  const { price, link, info } = menuItem;
  const { title } = link;
  return <div>
    <h4>{title}</h4>
    <div className="description">{info}</div>
    <div className="price">${(price/100).toFixed(2)}</div>
  </div>;
};

const StoreRating = ({ categories, rating: storeRating }:{ categories: string[], rating: StoreNode['rating'] })=>{
  const { priceRange, rating, count } = storeRating;
  return <div className="restaurant-details">
    { priceRange ? <span>{'$'.repeat(priceRange)} •</span> : undefined } {categories.slice(0, 2).join(', ')}
    { rating ? <div className="rating-stars">{rating} <StarSVG /> ({count}+)</div> : <div className="rating-stars">&nbsp;</div> }
  </div>;
};

const CaviarStore = ({ store, handlePopItemClick, active }:{ store:StoreNode, handlePopItemClick: (e) => void, active: boolean}) => {
  const { name, rating, menu:menus, storeUrl, categories } = store;
  const imageUrl = menus?.[0]?.menuItems?.[0]?.link?.imageUrl;

  return <div className={`restaurant${active ? ' active' : ''}`}>
    <a href={storeUrl} target="_blank" rel="noreferrer">
      <img src={imageUrl ? imageUrl.replace('cdn.doordash.com', 'img.cdn4dd.com/p/fit=cover,width=300,height=200,format=jpeg,quality=99') : ComingSoonPNG} alt={name} />
      <div className="restaurant-content">
        <h2>{name}</h2>
        <StoreRating categories={categories} rating={rating} />
        <div className="popular-items" onClick={handlePopItemClick}>
          <span>Popular Items</span>
          <button></button>
        </div>
      </div>
    </a>
    <StoreGrid className="store-grid">
      {menus.map((menu, m)=>menu.menuItems.slice(0,4).map((menuItem, i)=><CaviarMenuItem key={m+'-'+i} menuItem={menuItem} />))}
      <a href={storeUrl}>See Full Menu</a>
    </StoreGrid>
  </div>;
};

type PageData = {
  caviarCity: CityNode,
  allCaviarStore: {
    nodes: StoreNode[],
    totalCount: number
  },
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};

type RestaurantsProps = {
  activeCategory: ValidCategory | undefined,
  data: PageData,
  handlePopItemClick: (e,s) => void,
  activeStoreMenu: number | null,
  pageContext: {
    id: string,
    skip: number,
    limit: number,
    page: number,
    pages: number,
    pageSlug: string,
  },
};

export default function Restaurants({ activeCategory, data, handlePopItemClick, activeStoreMenu, pageContext }:RestaurantsProps) {
  const { page, pages, pageSlug } = pageContext;
  const { cityName, slug } = data.caviarCity;
  const totalStores = data.allCaviarStore.totalCount;
  const stores = data.allCaviarStore.nodes;

  return (
    <RestaurantStyle id="restaurants" className={`stores${activeCategory ? ' filtered' : ''}`}>
      <div className="stores-header">
        <h2><span className="filtered-hide">All restaurants in {cityName}</span><span className="filtered-show">{totalStores} results</span></h2>
        <Link className="reset-btn filtered-show" to={`/city/${slug}`}>Reset</Link>
      </div>
      <RestaurantGrid>
        {stores.map((store, s)=><CaviarStore handlePopItemClick={(e) => handlePopItemClick(e, s)} active={activeStoreMenu === s} key={s}  store={store} />)}
      </RestaurantGrid>
      <Pagination page={page} pages={pages} pageSlug={pageSlug} />
    </RestaurantStyle>
  );
}
