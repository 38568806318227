import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Section from './layout/Section';
import styled from 'styled-components';
import { CityNode, StoreNode } from '../api/caviar-api';
import Hero from './home/Hero';
import Categories from './home/Categories';
import Restaurants from './home/Restaurants';
import { validCategories, ValidCategory } from '../includes/categories';

const CaviarCityStyle = styled(Section)`
`;

const CityLayoutStyle = styled.div`
  border-bottom: 1px solid #e7e7e7;
  display: block;
  padding-bottom: 100px;
`;

type PageData = {
  caviarCity: CityNode,
  allCaviarStore: {
    nodes: StoreNode[],
    totalCount: number
  },
  site: {
    siteMetadata: {
      name: string,
      description: string,
    }
  }
};

type CaviarCityProps = {
  data: PageData,
  pageContext: {
    id: string,
    skip: number,
    limit: number,
    page: number,
    pages: number,
    pageSlug: string,
    filter?: {
      categories?: {
        in: string
      }
    }
  },
};

export default function CaviarCity({ data, pageContext }:CaviarCityProps ) {
  const { cityName, slug } = data.caviarCity;
  const [ activeStoreMenu, setActiveStoreMenu ] = useState(null);

  const categoryContext = pageContext.filter?.categories?.in as ValidCategory;
  let activeCategory;
  if (validCategories.indexOf(categoryContext) !== -1){
    activeCategory = categoryContext;
  }

  const handlePopItemClick = (e, s) => {
    e.preventDefault();
    if (activeStoreMenu === s) {
      setActiveStoreMenu(null);
    } else {
      setActiveStoreMenu(s);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="description" content={data.site.siteMetadata.description} />
        <title>{`${cityName} Food Delivery and Takeout | ${data.site.siteMetadata.name}`}</title>
      </Helmet>
      <CaviarCityStyle id="caviar-city">
        <Hero cityName={cityName} />
        <CityLayoutStyle>
          <Categories cityName={cityName} slug={slug} activeCategory={activeCategory} />
          <Restaurants activeCategory={activeCategory} data={data} handlePopItemClick={handlePopItemClick} activeStoreMenu={activeStoreMenu} pageContext={pageContext} />
        </CityLayoutStyle>
      </CaviarCityStyle>
    </>
  );
}

export const query = graphql`
  query ($id: String, $skip: Int, $limit: Int, $filter: CaviarStoreFilterInput ) {
    allCaviarStore(
        skip: $skip
        limit: $limit
        filter: $filter
        sort: {
          fields: [sortBucket, rating___count]
          order: [DESC, DESC]
        }
      ) {
      nodes {
        id
        name
        categories
        menu {
          name
          menuItems {
            price
            link {
              imageUrl
              title
            }
            info
          }
        }
        name
        storeId
        storeUrl
        rating {
          count
          priceRange
          rating
        }
      }
      totalCount
    }
    caviarCity(id: { eq: $id }) {
      slug
      cityName
      storeCount
      storeCategories
    }
    site {
      siteMetadata {
        name
        description
      }
    }
  }
`;
