import React, { useRef } from 'react';
import styled from 'styled-components';
import { stringToSlug } from '../../includes/string-utils';
import { Link } from 'gatsby';
//import Slider from 'react-slick';
//import 'slick-carousel/slick/slick.css';
//import 'slick-carousel/slick/slick-theme.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel, Navigation, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import PizzaPNG from '../../images/categories/pizza.png';
import SushiPNG from '../../images/categories/sushi.png';
import ThaiPNG from '../../images/categories/thai.png';
import ChinesePNG from '../../images/categories/chinese.png';
import RamenPNG from '../../images/categories/ramen.png';
import SoupPNG from '../../images/categories/soup.png';
import ItalianPNG from '../../images/categories/italian.png';
import VeganPNG from '../../images/categories/vegan.png';
import HealthyPNG from '../../images/categories/healthy.png';
import IndianPNG from '../../images/categories/indian.png';
import BurgersPNG from '../../images/categories/burgers.png';
import BreakfastPNG from '../../images/categories/breakfast.png';
import SaladPNG from '../../images/categories/salad.png';
import MexicanPNG from '../../images/categories/mexican.png';
import VegetarianPNG from '../../images/categories/vegetarian.png';
import SandwichesPNG from '../../images/categories/sandwiches.png';
import MediterraneanPNG from '../../images/categories/mediterranean.png';
import KoreanPNG from '../../images/categories/korean.png';
import { colors } from '../../includes/style';
import { media } from '../../includes/breakpoints';
import { validCategories, ValidCategory } from '../../includes/categories';

const StyledSection = styled.div`
  h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 50px;
    text-align: left;

    ${media.tablet`
      line-height: 18px;
    `};
  }

  .swiper-wrap {
    border-bottom: 1px solid #e7e7e7;
    display: flex; align-items: center;
    padding: 0 0 35px;
    position: relative;

    .swiper {
      margin-left: 0;

      ${media.tablet`
        overflow: hidden;
        scroll-behavior: auto;

        ~ .swiper-button-disabled {
          display: none;
        }
      `};

      .swiper-slide {
        display: flex; align-items: center; flex-direction: column;

        a {
          color: ${colors.black};
          display: inline-flex; flex-direction: column; align-items: center;
          font-size: 12px;

          img {
            max-width: 70px;
          }

          &:hover {
            img {
              transform: scale(1.2);
            }
          }
        }

        &.active {
          a {
            color: #eb1700;

            img {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }

  .arrow-btn {
    background: #F5F5F5;
    border: 0;
    border-radius: 50px;
    display: none;
    height: 32px;
    width: 32px;
    z-index: 8;

    ${media.tablet`
      display: block;
    `};

    &:after {
      border: 2px solid black;
      content: "";
      height: 5px;
      position: absolute; top: calc(50% - 4px); left: calc(50% - 5px);
      transform: rotate(45deg);
      width: 5px;
    }

    &.swiper-slide-next {
      position: absolute; top: 30px; right: -15px;

      &:after {
        border-bottom: none;
        border-left: none;
      }
    }

    &.swiper-slide-prev {
      position: absolute; top: 30px; left: -15px;

      &:after {
        border-top: none;
        border-right: none;
      }
    }
  }
`;

export const categoryImages: Record<ValidCategory, any> = {
  Pizza: PizzaPNG,
  Sushi: SushiPNG,
  Thai: ThaiPNG,
  Chinese: ChinesePNG,
  Ramen: RamenPNG,
  Soup: SoupPNG,
  Italian: ItalianPNG,
  Vegan: VeganPNG,
  Healthy: HealthyPNG,
  Indian: IndianPNG,
  Burgers: BurgersPNG,
  Breakfast: BreakfastPNG,
  Salad: SaladPNG,
  Mexican: MexicanPNG,
  Vegetarian: VegetarianPNG,
  Sandwiches: SandwichesPNG,
  Mediterranean: MediterraneanPNG,
  Korean: KoreanPNG,
} as const;

type CategoriesProps = {
  cityName: string,
  slug: string,
  activeCategory: ValidCategory | undefined,
};

export default function Categories({ cityName, slug, activeCategory }:CategoriesProps) {
  const swiperWrap = useRef<HTMLDivElement>(null);

  return (
    <StyledSection id="categories" className="filter">
      <div className="config">
        <h2>Browse cuisines in {cityName}</h2>
        <div className="swiper-wrap" ref={swiperWrap}>
          <Swiper
            className="swiper-start"
            direction={'horizontal'}
            freeMode={true}
            id="category-slider"
            modules={[FreeMode, Mousewheel, Navigation, A11y]}
            mousewheel={{
              forceToAxis: true,
            }}
            navigation= {{
              nextEl: '.arrow-btn.swiper-slide-next',
              prevEl: '.arrow-btn.swiper-slide-prev'
            }}
            slidesPerView={4}
            spaceBetween={15}
            breakpoints={{
              768: {
                slidesPerGroup: 8,
                slidesPerView: 8
              },
              960: {
                slidesPerGroup: 10,
                slidesPerView: 10
              },
              1200: {
                slidesPerGroup: 13,
                slidesPerView: 13
              }
            }}
            onSwiper={(swiper)=>{
              if (activeCategory){
                const index = validCategories.indexOf(activeCategory);
                if (index !== -1) swiper.slideTo(index);
              }
            }}
          >
            {
              Object.keys(categoryImages)?.map((category) =>
                <SwiperSlide key={category} className={`${activeCategory === category ? 'active' : ''}`}>
                  <Link to={`/city/${slug}/${stringToSlug(category)}`}>
                    <img src={categoryImages[category]} alt={category} />{category}
                  </Link>
                </SwiperSlide>)
            }
          </Swiper>
          <button className="arrow-btn swiper-slide-prev" type="button"></button>
          <button className="arrow-btn swiper-slide-next" type="button"></button>
        </div>
      </div>
    </StyledSection>
  );
}
