import React from 'react';
import NextArrowSVG from '../../images/next-arrow.inline.svg';
import PrevArrowSVG from '../../images/prev-arrow.inline.svg';
import { Link } from 'gatsby';

export function Pagination({ page, pages, pageSlug }: { page: number; pages: number; pageSlug: string; }) {
  const getPagination = (page, pages) => {
    const paginationCount: any = [];
    if (page < 4) {
      if (pages < 5) {
        for (let i = 1; i <= pages; i++)
          paginationCount.push(i);
      } else {
        for (let i = 1; i <= 4; i++)
          paginationCount.push(i);
      }
      if (pages > 5) {
        paginationCount.push('...');
        paginationCount.push(pages);
      }
    } else if (page > (pages - 3) && pages > 5) {
      paginationCount.push(1);
      paginationCount.push('...');
      for (let i = pages - 3; i <= pages; i++)
        paginationCount.push(i);
    } else {
      const prevPage = page - 1;
      const nextPage = page + 1;
      paginationCount.push(1);
      if (prevPage > 2)
        paginationCount.push('...');
      if (prevPage > 1)
        paginationCount.push(prevPage);
      if (page > 1 && page < pages)
        paginationCount.push(page);
      if (nextPage < pages)
        paginationCount.push(nextPage);
      if (nextPage < (pages - 1))
        paginationCount.push('...');
      paginationCount.push(pages);
    }
    return paginationCount;
  };

  const paginationCount = getPagination(page, pages);

  const onClick = () => {
    document.querySelector('#categories')?.scrollIntoView();
  };

  return <div className="restaurant-pagination">
    {pages > 1 ? <Link to={`/city/${pageSlug}/${page - 1}`} onClick={onClick}><PrevArrowSVG /></Link> : <span><PrevArrowSVG /></span>}
    <ul>
      {paginationCount.map((num, ind) => {
        if (typeof num === 'string')
          return (<li key={ind}>...</li>);
        else
          return (
            <li key={ind} className={page === num ? 'active' : ''}>
              <Link to={`/city/${pageSlug}/${num}`} onClick={onClick}>{num}</Link>
            </li>
          );
      })}
    </ul>
    {page < pages ? <Link to={`/city/${pageSlug}/${page + 1}`} onClick={onClick}><NextArrowSVG /></Link> : <span><NextArrowSVG /></span>}
  </div>;
}
